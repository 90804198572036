import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NewsCard } from '.';
import { PrevArrow, NextArrow } from '@/assets/icons/svg-icons';
import { useGetAllBlogsQuery } from '@/generated/graphql';

const SectionNews = () => {
  const [Swiper, setSwiper] = useState<any>(null);
  const [SwiperSlide, setSwiperSlide] = useState<any>(null);

  useEffect(() => {
    const getSwiperModules = async () => {
      const swiperComponents = await import('swiper/react');

      setSwiper(swiperComponents.Swiper);
      setSwiperSlide(swiperComponents.SwiperSlide);
    };

    getSwiperModules();
  }, []);

  const sliderRef = useRef<any>(null);
  const [filter, setFilter] = useState('all');

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const updateOffset = () => {
      const containerWidth = 1200;
      const windowWidth = window.innerWidth;
      const newOffset = (windowWidth - containerWidth) / 2;
      setOffset(newOffset);
    };

    // Cập nhật lần đầu và mỗi khi window resize
    updateOffset();
    window.addEventListener('resize', updateOffset);

    return () => {
      window.removeEventListener('resize', updateOffset);
    };
  }, []);

  const variables =
    filter === 'all'
      ? {}
      : {
          where: {
            category: {
              _eq: filter,
            },
          },
        };

  const { data } = useGetAllBlogsQuery({
    variables: variables,
  });
  return (
    <div className=" py-20">
      <div className="container">
        <p className="text-[46px] font-serifcustom text-[#758939]">
          Hãy để những hạnh phúc xanh lại nảy nở
        </p>

        <div className="flex justify-between w-full pb-6 pt-20">
          <div className="flex space-x-4">
            <button
              className={`${
                filter === 'all' &&
                'px-[8px] w-[89px]  text-darkgreen border border-darkgreen rounded-[40px]'
              } text-[#B3BE72]  text-[14px] w-[89px] py-[8px] duration-300 rounded-[40px]`}
              onClick={() => setFilter('all')}
            >
              Toàn bộ
            </button>
            <button
              className={`${
                filter === 'news' &&
                'px-[8px] w-[89px]  text-darkgreen border border-darkgreen rounded-[40px]'
              } text-[#B3BE72]  text-[14px] w-[89px] py-[8px] duration-300 rounded-[40px]`}
              onClick={() => setFilter('news')}
            >
              Tin tức
            </button>
            <button
              className={`${
                filter === 'report' &&
                'px-[8px] w-[89px]  text-darkgreen border border-darkgreen rounded-[40px]'
              } text-[#B3BE72]  text-[14px] w-[89px] py-[8px] duration-300 rounded-[40px]`}
              onClick={() => setFilter('report')}
            >
              Báo cáo
            </button>
          </div>

          <div className="flex gap-[28px]">
            <button
              onClick={handlePrev}
              // disabled={isBeginning}
              // style={{ opacity: isBeginning ? 0.5 : 1 }}
            >
              <PrevArrow />
            </button>
            <button
              onClick={handleNext}
              // disabled={isEnd}
              // style={{ opacity: isEnd ? 0.5 : 1 }}
            >
              <NextArrow className="fill-darkgreen" />
            </button>
          </div>
        </div>
      </div>
      {Swiper && SwiperSlide && (
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={60}
          initialSlide={0}
          slidesOffsetBefore={offset}
          slidesOffsetAfter={offset}
          ref={sliderRef}
          pagination={{ clickable: true }}
          className="w-full h-full pb-5 custom-swiper "
          // onSwiper={onSwiper}
          // onSlideChange={onSlideChange}
        >
          {data?.blogs.map((item, index) => (
            <SwiperSlide key={index} className="max-w-[428px]">
              <NewsCard {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default SectionNews;
